import React from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"

const Payment = () => {

    return (
        <Layout>
            <SEO title="Payment" />
            <div id="contactContentElem">
              <div id="contact_content">
                  <h1>Métodos de Pago</h1>
                  <div id="info">
                    
                  </div>
              </div>
            </div>
        </Layout>
    )
}

export default Payment;